// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--oyEUz";
export var box__ikd8O = "PlasmicFooter-module--box__ikd8O--3Mlur";
export var box__oaHlQ = "PlasmicFooter-module--box__oaHlQ--3iRp0";
export var box__oKt7L = "PlasmicFooter-module--box__oKt7L--2Zpjp";
export var iconLink__iZmh1 = "PlasmicFooter-module--iconLink__iZmh1--ZAH8f";
export var svg__iNk57 = "PlasmicFooter-module--svg__iNk57--jjPVN";
export var box__x8Gn5 = "PlasmicFooter-module--box__x8Gn5--2AkdM";
export var link__esl5S = "PlasmicFooter-module--link__esl5S--mhHQ2";
export var box__dpxy6 = "PlasmicFooter-module--box__dpxy6--2RkeX";
export var link__n9Ldu = "PlasmicFooter-module--link__n9Ldu--1bE94";
export var box__d6R1R = "PlasmicFooter-module--box__d6R1R--3DqBd";
export var box___1UOHg = "PlasmicFooter-module--box___1UOHg--CM3qZ";
export var box__ackXu = "PlasmicFooter-module--box__ackXu--Zjh6k";
export var link__hfzmQ = "PlasmicFooter-module--link__hfzmQ--371kS";
export var link__i5AnQ = "PlasmicFooter-module--link__i5AnQ--1ODXu";
export var link__sunEv = "PlasmicFooter-module--link__sunEv--2wA5v";
export var link__relPf = "PlasmicFooter-module--link__relPf--9qOcd";
export var link__b15Le = "PlasmicFooter-module--link__b15Le--3EI_U";
export var box___0SDwu = "PlasmicFooter-module--box___0SDwu--2kxHW";
export var box___02XaI = "PlasmicFooter-module--box___02XaI--1UITh";
export var link__hoWzX = "PlasmicFooter-module--link__hoWzX--2enB-";
export var link__ilqga = "PlasmicFooter-module--link__ilqga--3b7l8";
export var link__cph1 = "PlasmicFooter-module--link__cph1--DWzlp";
export var link__xOgcb = "PlasmicFooter-module--link__xOgcb--1aHbi";
export var link__ui1BI = "PlasmicFooter-module--link__ui1BI--1WzwC";
export var box__hiY93 = "PlasmicFooter-module--box__hiY93--uFKNs";
export var box__tOd3R = "PlasmicFooter-module--box__tOd3R--1lj2a";
export var link__xkHhi = "PlasmicFooter-module--link__xkHhi--3lOn_";
export var link___9Gw3I = "PlasmicFooter-module--link___9Gw3I--2hzIh";
export var link__hWn6F = "PlasmicFooter-module--link__hWn6F--kMSBr";
export var link__zfVr1 = "PlasmicFooter-module--link__zfVr1--1i1HR";
export var link__laQqH = "PlasmicFooter-module--link__laQqH--3T7Ka";
export var box__cS9Pa = "PlasmicFooter-module--box__cS9Pa--3fnG5";
export var box__c8Hvi = "PlasmicFooter-module--box__c8Hvi--3jorx";
export var box__bCumc = "PlasmicFooter-module--box__bCumc--2ip6k";
export var box__caJg0 = "PlasmicFooter-module--box__caJg0--1fdIZ";
export var textbox = "PlasmicFooter-module--textbox--1O3a3";
export var box__nmqpN = "PlasmicFooter-module--box__nmqpN--3QYQe";
export var button = "PlasmicFooter-module--button--27DKR";
export var svg__j5Rty = "PlasmicFooter-module--svg__j5Rty--1KDiw";
export var box___6Crrl = "PlasmicFooter-module--box___6Crrl--v6Ixv";
export var box___1Ortx = "PlasmicFooter-module--box___1Ortx--2jSWj";
export var box__mkYpG = "PlasmicFooter-module--box__mkYpG--760cE";
export var link__vmP5 = "PlasmicFooter-module--link__vmP5--NnTbT";
export var link__vxDxp = "PlasmicFooter-module--link__vxDxp--3WHGA";
export var box__yDz6T = "PlasmicFooter-module--box__yDz6T--3zY59";
export var box__thlU8 = "PlasmicFooter-module--box__thlU8--2_Sla";
export var iconLink__lPUeu = "PlasmicFooter-module--iconLink__lPUeu--iNzN3";
export var svg__y40Mp = "PlasmicFooter-module--svg__y40Mp--1quaR";
export var iconLink__gil8E = "PlasmicFooter-module--iconLink__gil8E--2z2jT";
export var svg__kZgcs = "PlasmicFooter-module--svg__kZgcs--1R2BX";
export var iconLink__wkwRy = "PlasmicFooter-module--iconLink__wkwRy--o3Wa9";
export var svg__ykzKx = "PlasmicFooter-module--svg__ykzKx--3sZlL";